import React, { useState } from 'react';

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
	const [authenticated, setAuthenticated] = useState(false);

	function setAuth(bool) {
		setAuthenticated(bool);
	}

	return (
		<AuthContext.Provider value={{ authenticated, setAuth }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
