import React from 'react';
import uberflipLogo from '../../images/uberflip-logo.svg';
import { OpenInNew } from '@material-ui/icons';

export default function UberflipListHeader() {
	return (
		<div style={{ display: 'flex' }}>
			<img
				src={uberflipLogo}
				alt="Uberflip Logo"
				style={{
					paddingLeft: '5px',
					marginTop: '16px',
					height: '30px',
				}}
			/>
			<h3
				className="text-labels"
				style={{
					paddingLeft: '10px',
				}}
			>
				<a
					style={{
						alignItems: 'center',
						display: 'flex',
					}}
					className="text-label-link"
					href="https://app.uberflip.com/hubs/tags/"
					rel="noopener noreferrer"
					target="_blank"
				>
					Tags
					<OpenInNew
						style={{
							paddingLeft: '5px',
						}}
						fontSize="small"
					></OpenInNew>
				</a>
			</h3>
		</div>
	);
}
