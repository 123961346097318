import React, { useState, useCallback } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Chip, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function SearchBar({
	textFieldRef,
	stackedFiltersRef,
	filterFunction,
	labelText,
	labelTextShort,
	color,
}) {
	const [labelShrink, setLabelShrink] = useState(false);

	const smallScreen = useMediaQuery('(max-width:700px)');

	const useStyles = makeStyles(() => ({
		root: {
			'& label.Mui-focused': {
				color: color,
			},
			'& .MuiOutlinedInput-root': {
				'&.Mui-focused fieldset': {
					borderColor: color + '99',
				},
			},
		},
		labelRoot: labelShrink ? {} : { marginLeft: 30 },
	}));
	const styles = useStyles();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedFilterFunction = useCallback(
		_.debounce(filterFunction, 250, [])
	);

	const filtersExist = () => {
		return (
			textFieldRef.current?.length > 0 ||
			stackedFiltersRef.current?.length > 0
		);
	};

	return (
		<Autocomplete
			multiple
			options={[]}
			freeSolo
			clearOnEscape
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip
						variant="outlined"
						label={option}
						{...getTagProps({ index })}
					/>
				))
			}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					label={smallScreen ? labelTextShort : labelText}
					className={styles.root}
					fullWidth
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<>
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
								{params.InputProps.startAdornment}
							</>
						),
					}}
					InputLabelProps={{
						shrink: labelShrink,
						classes: {
							root: styles.labelRoot,
						},
					}}
				/>
			)}
			onChange={(e, value) => {
				stackedFiltersRef.current = value;
				debouncedFilterFunction();
			}}
			onInputChange={(e, value) => {
				textFieldRef.current = value;
				debouncedFilterFunction();
			}}
			onFocus={() => {
				setLabelShrink(true);
			}}
			onBlur={() => {
				setLabelShrink(filtersExist());
			}}
		/>
	);
}
