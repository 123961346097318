import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const DeleteModal = ({ tag, displayModal, setDisplayModal, deleteTag }) => {
	const styles = useStyles();
	const handleClose = () => {
		setDisplayModal(false);
	};

	return (
		<>
			<Modal
				className={styles.modal}
				open={displayModal}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={displayModal}>
					<div className={styles.paper}>
						<h2
							id="transition-modal-title"
							style={{ fontSize: '16px' }}
						>
							Delete Tag "{tag.uberflipTag}"
						</h2>{' '}
						<hr className={styles.hr} />
						<div
							style={{
								padding: '15px 0px 15px 0px',
								fontSize: '13px',
							}}
						>
							<div style={{ marginBottom: '12px' }}>
								<b>
									Are you sure you want to permanently delete
									this Tag?
								</b>
							</div>
							<div style={{ marginBottom: '12px' }}>
								The following tag has
								<b style={{ paddingRight: '4px' }}>
									{' '}
									{tag.uberflipItemCount}
								</b>
								<b>
									referencing Item
									{tag.uberflipItemCount > 1 ? (
										<>s. </>
									) : (
										<>. </>
									)}
								</b>
								This action will remove the tag from all Items
								in all Hubs that currently use it as well as
								from any filter or scheduled task that
								references it.
							</div>
						</div>
						<hr className={styles.hr} />
						<div className={styles.footer}>
							<Button
								variant="outlined"
								className={styles.cancel_button}
								onClick={handleClose}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className={styles.delete_button}
								onClick={() => {
									deleteTag(tag);
									handleClose();
								}}
							>
								Delete Tag
							</Button>
						</div>
					</div>
				</Fade>
			</Modal>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		wordWrap: 'break-word',
		borderRadius: '5px !important',
		maxWidth: '600px',
		marginBottom: '25%',
		padding: '15px',
		lineHeight: '1.5',
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '5px 0px 0px 5px',
	},
	cancel_button: {
		textTransform: 'none',
	},
	delete_button: {
		backgroundColor: '#5bc0de',
		marginLeft: '6px',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: '#2f96b4',
		},
	},
	hr: {
		border: '1px solid #e5e5e5',
	},
}));

DeleteModal.propTypes = {
	tag: PropTypes.object.isRequired,
	displayModal: PropTypes.bool.isRequired,
	setDisplayModal: PropTypes.func.isRequired,
	deleteTag: PropTypes.func.isRequired,
};

export default DeleteModal;
