import React, { useContext } from 'react';
import { Warning, OpenInNew } from '@material-ui/icons';
import { TagsContext } from '../../context/tag/index';

export default function PartnerErrorMessage() {
	const { appId } = useContext(TagsContext);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: '55px 10px 45px 10px',
				textAlign: 'center',
			}}
		>
			<div>
				<Warning fontSize="large" />
			</div>

			<div>Unable to access your 6sense data.</div>
			<div
				style={{
					paddingTop: '5px',
					fontSize: '13px',
					fontWeight: '500',
					color: '#00000076',
					margin: '0px',
				}}
			>
				Please check your API Key in the
			</div>
			<div>
				<a
					className="text-hyperlink-label"
					href={`https://app.uberflip.com/account/app_configuration/${appId}`}
					rel="noopener noreferrer"
					target="_blank"
					style={{
						display: 'flex',
						alignItems: 'center',
						paddingTop: '4px',
						textAlign: 'center',
						fontSize: '13px',
						fontWeight: '500',
					}}
				>
					App Configuration
					<OpenInNew
						style={{
							paddingLeft: '5px',
						}}
						fontSize="small"
					></OpenInNew>
				</a>
			</div>
		</div>
	);
}
