import React, { useState, useEffect, useContext, useReducer } from 'react';
import Axios from 'axios';
import PartnerList from '../components/partnerList/PartnerList';
import UberflipList from '../components/uberflipList/UberflipList';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { TagsProvider, TagReducer, TAG_ACTIONS } from '../context/tag/index';
import { AuthContext } from '../context/auth/index';
import GetHelp from './GetHelp';

export default function MainUI() {
	Axios.defaults.withCredentials = true;

	const [tagState, dispatch] = useReducer(TagReducer, []);
	const [isListLoading, setIsListLoading] = useState(true);
	const [partnerDataSuccess, setPartnerDataSuccess] = useState(null);
	const [uberflipDataSuccess, setUberflipDataSuccess] = useState(null);
	const [appId, setAppId] = useState('');
	const { authenticated, setAuth } = useContext(AuthContext);

	useEffect(() => {
		if (!authenticated) return;
		setIsListLoading(true);
		Axios.post('convergence/')
			.then((res) => {
				dispatch({
					type: TAG_ACTIONS.SET_TAGS,
					payload: res.data.data,
				});
				console.log('this is res.data', res.data);
				setPartnerDataSuccess(res.data.partnerAPISuccess);
				setUberflipDataSuccess(res.data.uberflipAPISuccess);
				setAppId(res.data.appId);
				setIsListLoading(false);
			})
			.catch((e) => {
				console.log('we are in the error', e);
				if (e.response?.status === 401 || e.response?.status === 403) {
					Axios.get('/api/saml/clear-cookie');
					setAuth(false);
				}
				setIsListLoading(false);
				setPartnerDataSuccess(false);
				setUberflipDataSuccess(false);
			});
	}, [authenticated, setAuth]);

	return (
		<TagsProvider
			value={{
				tagState,
				dispatch,
				isListLoading,
				partnerDataSuccess,
				uberflipDataSuccess,
				appId,
			}}
		>
			<div style={{ paddingLeft: '20px' }}>
				<div style={{ display: 'flex', alignContent: 'center' }}>
					<h4
						className="text-labels"
						style={{
							fontSize: '26px',
						}}
					>
						6sense Personalizer - Tag Manager
					</h4>
					<GetHelp />
				</div>
				<p
					style={{
						color: '#777',
						fontSize: '13px',
					}}
				>
					Create or delete Uberflip tags by selecting through the
					following 6sense attributes.
				</p>
			</div>
			<div className="ui-container">
				<PartnerList></PartnerList>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
					}}
				>
					{' '}
					<SyncAltIcon style={{ color: '#AAAAAA', fontSize: 45 }} />
				</div>
				<UberflipList></UberflipList>
			</div>
		</TagsProvider>
	);
}
