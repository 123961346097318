import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default function DataLoadingMessage({ dataName }) {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: '55px 10px 45px 10px',
				textAlign: 'center',
			}}
		>
			<div>
				{' '}
				<CircularProgress size={30} />
			</div>
			<div>Retrieving your {dataName} data!</div>
			<div
				style={{
					paddingTop: '5px',
					fontSize: '13px',
					fontWeight: '500',
					color: '#00000076',
					margin: '0px',
				}}
			>
				This will only take a moment...
			</div>
		</div>
	);
}
