import React from 'react';
import { Warning } from '@material-ui/icons';

export default function UberflipErrorMessage() {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: '55px 10px 45px 10px',
				textAlign: 'center',
			}}
		>
			<div>
				<Warning fontSize="large" />
			</div>
			<div>Unable to access your Uberflip data.</div>
			<div
				style={{
					paddingTop: '5px',
					fontSize: '13px',
					fontWeight: '500',
					color: '#00000076',
					margin: '0px',
				}}
			>
				If this issue persists, please contact Uberflip Support.
			</div>
		</div>
	);
}
