import React from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

export default function VirtualizedList({ renderRow, itemCount }) {
	return (
		<AutoSizer>
			{({ height, width }) => (
				<List
					className="List"
					height={height}
					itemCount={itemCount}
					itemSize={50}
					width={width}
				>
					{renderRow}
				</List>
			)}
		</AutoSizer>
	);
}
