import React, { useState } from 'react';
import VirtualizedList from '../VirtualizedList';
import DeleteModal from './DeleteModal';
import { ListItem, ListItemText, Tooltip, Fade } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

export default function UberflipListItems({ tagsFiltered, deleteTag }) {
	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [selectedTag, setSelectedTag] = useState({});

	const handleTagDelete = (tag) => {
		setSelectedTag(tag);

		if (tag.uberflipItemCount > 0) {
			setDisplayDeleteModal(true);
			return;
		} else {
			deleteTag(tag);
		}
	};

	function renderRow(props) {
		const { index, style } = props;
		const tag = tagsFiltered[index];
		return (
			<Tooltip
				title="Click to DELETE tag"
				placement="right"
				TransitionProps={{ timeout: 400 }}
				enterDelay={800}
				enterNextDelay={800}
				TransitionComponent={Fade}
			>
				<ListItem
					className="list-item"
					button
					style={style}
					key={index}
					onClick={() => handleTagDelete(tag)}
				>
					<ListItemText
						className="list-item-tagInfo"
						secondary={tag.uberflipTag}
						primary={`${tag.partnerValue}`}
					/>
					<ListItemText
						className="list-item-tagCount"
						secondary={`Items tagged: ${
							tag.uberflipItemCount ? tag.uberflipItemCount : 0
						}`}
					/>

					<Close className="list-item-icon"></Close>
				</ListItem>
			</Tooltip>
		);
	}

	renderRow.propTypes = {
		index: PropTypes.number.isRequired,
		style: PropTypes.object.isRequired,
	};

	return (
		<>
			<VirtualizedList
				renderRow={renderRow}
				itemCount={tagsFiltered.length}
			></VirtualizedList>
			<DeleteModal
				tag={selectedTag}
				displayModal={displayDeleteModal}
				setDisplayModal={setDisplayDeleteModal}
				deleteTag={deleteTag}
			/>
		</>
	);
}
