export const TAG_ACTIONS = {
	SET_TAGS: 'GET_TAGS',
	CREATE_TAG: 'CREATE_TAG',
	DELETE_TAG: 'DELETE_TAG',
};

export const TagReducer = (tagState, action) => {
	switch (action.type) {
		case TAG_ACTIONS.SET_TAGS:
			return action.payload;

		case TAG_ACTIONS.CREATE_TAG:
			const { tag: createTag } = action.payload;
			const { id, items_count, tag_group_id } = action.payload.metadata;
			const createTagEditted = {
				...createTag,
				uberflipTagId: id,
				uberflipItemCount: items_count,
				uberflipTagGroupId: tag_group_id,
				inUberflip: true,
			};
			return tagState.map((element) => {
				return element === createTag ? createTagEditted : element;
			});

		case TAG_ACTIONS.DELETE_TAG:
			const { tag: deleteTag } = action.payload;

			const deleteTagEditted = {
				...deleteTag,
				uberflipTagId: '',
				uberflipItemCount: '',
				uberflipTagGroupId: '',
				inUberflip: false,
			};

			return tagState.map((element) => {
				return element === deleteTag ? deleteTagEditted : element;
			});

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
