import React from 'react';
import { Search } from '@material-ui/icons';

export default function NoItemsMessage({ itemName }) {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: '45px 0px 45px 0px',
			}}
		>
			<div>
				{' '}
				<Search fontSize="large" />
			</div>
			<div>No {itemName} found.</div>
			<div
				style={{
					paddingTop: '5px',
					fontSize: '13px',
					fontWeight: '500',
					color: '#00000076',
					margin: '0px',
				}}
			>
				Please try searching again!
			</div>
		</div>
	);
}
