import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/auth';
export default function PrivateRoute({ children, ...rest }) {
	const { authenticated } = useContext(AuthContext);

	return (
		<Route
			{...rest}
			render={() => {
				return authenticated === true ? (
					children
				) : (
					<Redirect to="/authenticate" />
				);
			}}
		/>
	);
}
