import React from 'react';
import partnerLogo from '../../images/partner-logo.svg';

export default function PartnerListHeader() {
	return (
		<div style={{ display: 'flex', marginBottom: '10px' }}>
			<img
				src={partnerLogo}
				alt="Partner Logo"
				style={{
					paddingLeft: '5px',
					marginTop: '11px',
					height: '26px',
				}}
			/>{' '}
			<h3
				className="text-labels"
				style={{
					marginBottom: '7px',
					paddingLeft: '10px',
				}}
			>
				Attributes
			</h3>
		</div>
	);
}
