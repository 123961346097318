import React from 'react';
import './styles/container.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainUI from './pages/MainUI';
import Authenticate from './pages/Authenticate';
import { AuthProvider } from './context/auth/authContext';
import PrivateRoute from './components/PrivateRoute';

function App() {
	return (
		<AuthProvider>
			<Router>
				<Switch>
					<Route path="/authenticate" component={Authenticate} />
					<PrivateRoute path="/">
						<MainUI />
					</PrivateRoute>
				</Switch>
			</Router>
		</AuthProvider>
	);
}

export default App;
