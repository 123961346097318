import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import AuthContext from '../context/auth/authContext';

export default function Authenticate({ location }) {
	const [unauthorized, setUnauthorized] = useState(false);
	const [validatingToken, setValidatingToken] = useState(false);
	const [componentMounted, setComponentMounted] = useState(true);
	const { authenticated, setAuth } = useContext(AuthContext);

	const { key } = qs.parse(location.search, {
		ignoreQueryPrefix: true,
		decoder: (c) => c,
	});

	useEffect(() => {
		const check = async () => {
			const redirectUrl = await Axios.get('/api/saml/saml-request');
			const { loginUrl } = redirectUrl.data;
			window.location.replace(loginUrl);
		};

		const checkAuthStatus = async () => {
			const authStatus = await Axios({ url: '/api/saml/auth-status' });
			const { authenticated } = authStatus.data;

			if (!authenticated) {
				check();
			}
		};

		checkAuthStatus();
	}, []);

	async function getToken(ufKey) {
		if (!ufKey) throw new Error();
		const result = await Axios({
			url: 'https://auth.mpa.uberflip.com/token/',
			method: 'POST',
			data: {
				ufKey,
			},
			withCredentials: false,
		});

		return result;
	}

	function checkTokenStatus() {
		Axios.defaults.withCredentials = true;
		Axios.post('auth/status')
			.then((res) => {
				if (res.status === 200) setAuth(true);
				else {
					setAuth(false);
				}
				setValidatingToken(false);
			})
			.catch(async (e) => {
				await Axios.post('/api/saml/clear-cookie');
				setAuth(false);
				setValidatingToken(false);
			});
	}

	async function loginUser(autoAuthToken) {
		if (!autoAuthToken) throw new Error();
		const result = await Axios({
			url: 'auth/login',
			method: 'POST',
			data: {
				autoAuthToken,
			},
			withCredentials: false,
		});

		return result;
	}

	useEffect(() => {
		if (componentMounted) {
			if (key) {
				getToken(key).then((res) => {
					loginUser(res.data.token)
						.then((res) => {
							if (res.status === 200) {
								setAuth(true);
								setUnauthorized(false);
							}
						})
						.catch((e) => {
							setAuth(false);
							setUnauthorized(true);
						});
				});
			} else {
				checkTokenStatus();
			}
		}
		return () => {
			setComponentMounted(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (authenticated) return <Redirect to="/" />;
	if (validatingToken) return <div></div>;
	if (!key && !authenticated) {
		return (
			<div>
				[Local development] won't redirect to auth.mpa.uberflip.com. Hit
				the URL manually and copy the ?key into /authenticate?key=....
			</div>
		);
	}
	if (unauthorized) return <div>Unauthorized.</div>;
	return <div></div>;
}
