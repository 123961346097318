import React from 'react';
import VirtualizedList from '../VirtualizedList';
import { ListItem, ListItemText, Tooltip, Fade } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import PropTypes from 'prop-types';

export default function PartnerListItems({ tagsFiltered, createTag }) {
	function renderRow(props) {
		const { index, style } = props;
		const tag = tagsFiltered[index];
		return (
			<Tooltip
				title="Click to CREATE tag"
				placement="right"
				TransitionProps={{ timeout: 400 }}
				enterDelay={800}
				enterNextDelay={800}
				TransitionComponent={Fade}
			>
				<ListItem
					className="list-item"
					button
					style={style}
					key={index}
					onClick={() => createTag(tag)}
				>
					<ListItemText
						className="list-item-tagInfo"
						secondary={tag.uberflipTag}
						primary={`${tag.partnerValue}`}
					/>
					<ArrowForward className="list-item-icon"></ArrowForward>
				</ListItem>
			</Tooltip>
		);
	}

	renderRow.propTypes = {
		index: PropTypes.number.isRequired,
		style: PropTypes.object.isRequired,
	};

	return (
		<VirtualizedList
			renderRow={renderRow}
			itemCount={tagsFiltered.length}
		></VirtualizedList>
	);
}
