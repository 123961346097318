import React from 'react';
import { Container } from '@material-ui/core';

export default function ListFooter({
	dataName,
	filteredCount,
	totalCount,
	itemName,
	dataSuccess,
}) {
	return (
		<Container className="list-footer">
			<div>
				<h5 className="text-labels">
					{!dataSuccess && dataName === 'Partner' ? (
						`0 of 0 ${itemName}`
					) : (
						<>
							{filteredCount} of {totalCount} {itemName}
						</>
					)}
				</h5>
			</div>
		</Container>
	);
}
