import React, { useContext, useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import { TagsContext, TAG_ACTIONS } from '../../context/tag/index';
import { AuthContext } from '../../context/auth/index';
import NoItemsMessage from '../NoItemsMessage';
import SearchBar from '../SearchBar';
import UberflipListHeader from './UberflipListHeader';
import ListFooter from '../ListFooter';
import UberflipErrorMessage from '../UberflipErrorMessage';
import DataLoadingMessage from '../DataLoadingMessage';
import UberflipListItems from './UberflipListItems';

export default function UberflipList() {
	Axios.defaults.withCredentials = true;
	const {
		tagState,
		isListLoading,
		dispatch,
		partnerDataSuccess,
		uberflipDataSuccess,
	} = useContext(TagsContext);
	const { setAuth } = useContext(AuthContext);
	const [uberflipOnlyTags, setUberflipOnlyTags] = useState([]);
	const [tagsFiltered, setTagsFiltered] = useState([]);
	const stackedFiltersRef = useRef([]);
	const textFieldRef = useRef(null);

	useEffect(() => {
		let newTags = tagState?.filter((tag) => tag.inUberflip);
		setUberflipOnlyTags(newTags);
	}, [tagState]);

	useEffect(() => {
		filterFunction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uberflipOnlyTags]);

	const deleteTag = (tag) => {
		const payload = {
			uberflipTag: tag.uberflipTag,
			uberflipTagId: tag.uberflipTagId,
		};

		Axios.post('uberflip/delete-tag', payload)
			.then((res) => {
				dispatch({
					type: TAG_ACTIONS.DELETE_TAG,
					payload: { tag },
				});
			})
			.catch((e) => {
				console.log(e);
				if (e.response?.status === 401 || e.response?.status === 403) {
					Axios.get('/api/saml/clear-cookie');
					setAuth(false);
				}
			});
	};

	const filterFunction = () => {
		//Get only tags that aren't in uberflip
		let newTags = [...uberflipOnlyTags];
		//Collect filters
		const stackedFilters = stackedFiltersRef.current;
		let filters = [...stackedFilters];
		const textField = textFieldRef.current;
		if (textField) filters.push(textField);
		//Filter tags based on search box
		if (filters.length !== 0) {
			newTags = newTags.filter((tag) =>
				multiSearchAnd(
					`${tag.partnerValue} ${tag.partnerSubAttribute} ${tag.partnerAttribute}`,
					filters
				)
			);
		}
		setTagsFiltered(newTags);
	};

	const multiSearchAnd = (text, searchWords) =>
		searchWords.every((el) => {
			return text.match(new RegExp(el, 'i'));
		});

	return (
		<div className="grid-col-container">
			<UberflipListHeader />
			<SearchBar
				textFieldRef={textFieldRef}
				stackedFiltersRef={stackedFiltersRef}
				filterFunction={filterFunction}
				labelText="Search for an Uberflip tag..."
				labelTextShort="Search tags..."
				color="#ce0058"
			/>
			<div className="list-container">
				{isListLoading ? (
					<DataLoadingMessage dataName="Uberflip" />
				) : tagsFiltered.length > 0 ? (
					<UberflipListItems
						tagsFiltered={tagsFiltered}
						deleteTag={deleteTag}
					/>
				) : !uberflipDataSuccess ? (
					<UberflipErrorMessage />
				) : (
					<NoItemsMessage itemName="tags" />
				)}
			</div>
			<ListFooter
				dataName="Uberflip"
				filteredCount={tagsFiltered?.length}
				totalCount={uberflipOnlyTags?.length}
				itemName="tags"
				dataSuccess={partnerDataSuccess}
			/>
		</div>
	);
}
