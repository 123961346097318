import React from 'react';
import { makeStyles, Button, Menu, MenuItem, Fade } from '@material-ui/core';
import { ExpandMore, Help, OpenInNew } from '@material-ui/icons';

export default function GetHelp() {
	const styles = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenInNew = (url) => {
		window.open(url, '_blank').focus();
	};

	return (
		<>
			<div style={{ marginLeft: 'auto', padding: '40px 32px 0px 0px' }}>
				<Button
					startIcon={<Help />}
					endIcon={<ExpandMore />}
					onClick={handleClick}
				>
					Get Help
				</Button>
				<Menu
					id="fade-menu"
					className={styles.menu}
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					<MenuItem className={styles.menuHeading} disabled>
						Resources
					</MenuItem>

					<hr className={styles.hr} />

					<MenuItem
						className={styles.menuItem}
						onClick={() =>
							handleOpenInNew(
								'https://help.uberflip.com/hc/en-us/articles/360039621191-6sense-Personalizer-User-Guide#creating-attribute-tags-with-6sense-tag-manager'
							)
						}
					>
						Open User Guide{' '}
						<OpenInNew
							className={styles.OpenInNew}
							fontSize="small"
						/>
					</MenuItem>
					<MenuItem
						className={styles.menuItem}
						onClick={() =>
							handleOpenInNew(
								'https://help.uberflip.com/hc/en-us/requests/new'
							)
						}
					>
						Contact Support
						<OpenInNew
							className={styles.OpenInNew}
							fontSize="small"
						/>
					</MenuItem>
				</Menu>
			</div>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	menu: {
		'& .MuiPaper-root': {
			minWidth: '160px',
		},
	},
	hr: {
		border: '1px solid #e5e5e5',
	},
	menuHeading: {
		textTransform: 'uppercase',
		fontSize: '12px',
		lineHeight: '1.45455',
		display: 'flex',
		paddingTop: '15px',
	},
	menuItem: {
		cursor: 'pointer',
		fontSize: '16px',
		fontWeight: '500',
	},
	OpenInNew: {
		paddingLeft: '10px',
		marginLeft: 'auto',
	},
}));
